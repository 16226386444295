<template>
  <div class="words-table">
    <el-table :data="list" style="width: 100%" border>
      <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
      <el-table-column prop="name" label="分类" align="center"></el-table-column>
      <el-table-column prop="content" label="内容" align="center">
        <template slot-scope="scope">
          <el-tag
            v-for="(item, index) in scope.row.list"
            :key="index"
            type="info"
            size="small"
            effect="plain">
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="$emit('edit', scope.row)"
          >编辑
          </el-button>
          <el-button
            v-if="isCanDelete"
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="$emit('del', scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    isCanDelete: {
      type: Boolean,
      default: () => true,
    },
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.words-table {
  .el-tag + .el-tag {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
</style>
