import API from "@/api";

export default {
  getWords: params => {
    return API.requestGet({ url: "keyword/list", params });
  },
  save: params => {
    return API.requestPost({ url: "keyword/save", data: params });
  },
  destroy: params => {
    return API.requestPost({ url: "keyword/destroy", data: params });
  },
  add: params => {
    return API.requestPost({ url: "keyword/addkeyword", data: params });
  },
  del: params => {
    return API.requestGet({ url: "keyword/deletekeyword", params });
  },
  edit: params => {
    return API.requestPost({ url: "keyword/edikeyword", data: params });
  },
};
