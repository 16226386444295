<template>
  <el-dialog
    :title="this.form.id ? '修改' : '添加'"
    :visible.sync="isShowEdit"
    :width="width"
    top="30vh"
    :show-close="false"
    :modal="false"
    :close-on-click-modal="false"
    @open="openDialog"
  >
    <el-form ref="form" label-width="80px" :rules="rules" :model="form" hide-required-asterisk>
      <el-form-item label="分类:" prop="name" v-if="isGroupCanEdit">
        <el-input v-model.trim="form.name" placeholder="请输入分类" :disabled="this.form.id">
        </el-input>
      </el-form-item>
      <el-form-item label="关键词:" prop="list">
        <div class="word">
          <el-tag
            v-for="(tag, index) in form.list"
            :key="index"
            :closable="true"
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 关键词</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="textRight">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<!--suppress ExceptionCaughtLocallyJS -->
<script>
import {Message} from "element-ui";

export default {
  props: {
    isShowEdit: {
      type: Boolean,
      default: false
    },
    isGroupCanEdit: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    const checkList = (rule, value, callback) => {
      if (this.form.list.length === 0) {
        callback(new Error('关键词不能为空'));
      }
      callback();
    };
    return {
      form: {
        name: '',
        list: [],
      },
      inputVisible: false,
      inputValue: '',
      rules: {
        name: [
          {required: true, message: '请填写分类', trigger: 'blur'},
          {pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/, message: '分类应为中文、英文、数字或下划线组成', trigger: 'blur'}
        ],
        list: [{ validator: checkList, trigger: "blur" }]
      },
      width:'30%'
    }
  },
  methods: {
    openDialog() {
      if(document.documentElement.clientWidth < 480 ){
        this.width = '80%'
      }
      this.form = JSON.parse(JSON.stringify(this.editData));
    },
    close() {
      this.$emit('close')
      this.$refs['form'].resetFields();
    },
    confirm() {
      let check = false;
      this.$refs['form'].validate(res => (check = res));
      if (check) {
        this.$emit('confirm', {
          ...this.form,
          content: this.form.list.join(',')
        })
        this.$refs['form'].resetFields();
      }
    },
    handleClose(tag) {
      this.form.list.splice(this.form.list.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let needClear = true;
      try {
        let inputValue = this.inputValue.trim();
        if (!inputValue)
          return;
        if (this.form.list.indexOf(inputValue) >= 0)
          return;
        if (!inputValue.match(/^[^,]*$/))
          throw new Error('不可输入特殊字符');
        this.form.list.push(inputValue);
      } catch (e) {
        needClear = false;
        Message.error(e.message);
      } finally {
        if (needClear) {
          this.inputVisible = false;
          this.inputValue = '';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.word {
  text-align: left;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
