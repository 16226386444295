<template>
  <div class="user-box">
    <el-tabs type="card" v-model="activeName" @tab-click="changeTab">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.label" :name="item.name">
        <words-head @add="addWords" :isShow="activeName!=='sms'"></words-head>
        <words-table :list="table[activeName]" :is-can-delete="activeName !== 'sms'" @del="delWord" @edit="editWord"></words-table>
      </el-tab-pane>
    </el-tabs>
    <edit-dialog
      :isShowEdit="isShowEdit"
      :editData="editData"
      @close="isShowEdit = false"
      @confirm="confirm"
      :is-group-can-edit="activeName!=='sms'"
    ></edit-dialog>
  </div>
</template>
<script>
// components
import wordsHead from './components/words-head';
import wordsTable from './components/words-table';
import editDialog from "./components/edit-dialog";
// api
import service from './api';

export default {
  data() {
    return {
      activeName: 'sms',
      tabs: [
        {label: '短信关键词', name: 'sms'},
        {label: '应用关键词', name: 'app'}
      ],
      table: {
        sms: [], //  短信关键词表
        app: [] //  应用关键词表
      },
      isShowEdit: false,
      editData: {},
    };
  },
  components: {
    wordsHead,
    wordsTable,
    editDialog
  },
  methods: {
    // 获取关键词
    getWords(type) {
      return service.getWords({code: type}).then(res => {
        this.table[type] = res.map(item => ({
          ...item,
          list: item.content.split(',')
        }))
      });
    },
    // 切换tab
    changeTab() {
      let {activeName = 'sms', table = {}} = this;
      if (!table[activeName].length) this.getWords(activeName);
    },
    // 添加关键词
    addWords(form) {
      this.editWord({
        name: '',
        content: '',
        list: [],
      })
    },
    // 删除关键词
    delWord(row) {
      this.$alert('确认删除该应用关键词？','提示',{showCancelButton:true}).then(() => {
        return service.destroy({
          code: row.code,
          name: row.name,
        }).then(res => {
          this.$notify({ message: '删除成功！', type: 'success', offset: 400, duration: 800 });
          this.getWords(this.activeName);
        });
      }).catch()
    },
    //编辑关键词
    editWord(row) {
      this.isShowEdit = true;
      this.editData = row;
    },
    confirm(form) {
      this.isShowEdit = false;
      return service.save({
        name: form.name,
        content: form.content,
        code: this.editData.code||this.activeName+'_'+ new Date().getTime(),
      }).then(() => {
        this.$notify({message: '保存成功！', type: 'success', offset: 400, duration: 800});
        this.getWords(this.activeName);
      });
    }
  },
  mounted() {
    this.getWords(this.activeName);
  }
};
</script>

<style lang="scss" scoped>
.user-box {
  min-height: 100%;
}
</style>
